.live-events {
  padding: 20px 0;
}

.live-container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-header {
  margin-bottom: 20px;
  text-align: center;
}

.section-header h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #0e1b4d;
}

.mobile-section-header {
  display: none;
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.event-card {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  height: 160px;
  width: 600px;
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.event-card.live {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(248, 34, 73, 0.7), 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(248, 34, 73, 0), 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(248, 34, 73, 0), 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}

.event-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.event-details {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.event-details h3 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  color: #0e1b4d;
}

.next-event {
  font-size: 15px;
  color: #112363;
  margin-bottom: 8px;
}

.venue {
  font-size: 15px;
  color: #f82249;
  margin-top: 8px;
  padding-right: 80px;
}

.live-indicator {
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: rgba(248, 34, 73, 0.9);
  color: white;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 6px;
  box-shadow: 0 2px 4px rgba(248, 34, 73, 0.4);
}

@media (max-width: 1200px) {
  .event-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 992px) {
  .event-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .event-card{
    width: 500px;
  }
}

@media (max-width: 768px) {
  .mobile-section-header {
    display: block;
    margin-bottom: 20px;
    text-align: center;
  }

  .mobile-section-header h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    color: #0e1b4d;
  }

  .section-header {
    display: none;
  }

  .event-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .event-card {
    width: 400px;
    margin: 0 auto;
    height: 160px;
  }

  .event-details h3 {
    font-size: 18px;
  }

  .next-event, .venue {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .event-card {
    height: 160px;
    width: 300px;
  }
}