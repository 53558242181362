.page-container {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    padding: 80px 15px 40px;
    /* Added top and bottom padding */
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: calc(100vh - 120px);
    /* Adjust based on header and footer heights */
}

.page-title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 40px;
    /* Adjusted margin */
    color: #0e1b4d;
}

.page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .page-container {
        padding: 60px 10px 30px;
        /* Adjusted padding for mobile */
    }

    .page-title {
        font-size: 28px;
        margin: 0 0 30px;
        /* Adjusted margin */
    }
}