body {
  font-family: "Arial", sans-serif;
  background-color: #f4f7f9;
  margin: 0;
  padding: 0;
}

.admin-login {
  width: 300px;
  margin: 100px auto;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admin-login h2 {
  margin-top: 0;
  color: #333;
  text-align: center;
}

.admin-login input {
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.admin-login button,
.admin-panel button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #0e1b4d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.admin-login button:hover,
.admin-panel button[type="submit"]:hover {
  background-color: #1a2a5e;
}

.admin-panel {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.admin-panel h1 {
  color: #333;
  margin-bottom: 30px;
}

.tabs {
  display: flex;
  margin-bottom: 30px;
  border-bottom: 2px solid #f0f0f0;
}

.tabs button {
  padding: 15px 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #777;
  transition: all 0.3s ease;
}

.tabs button.active {
  color: #0e1b4d;
  border-bottom: 2px solid #0e1b4d;
}

.live-events-form .venue-form,
.leaderboard-form .leaderboard-grid {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 30px;
}

.live-events-form input[type="text"],
.leaderboard-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.leaderboard-form .header-cell,
.leaderboard-form .event-cell,
.leaderboard-form .position-cell {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: white;
}

.leaderboard-form .header-cell {
  font-weight: bold;
  background-color: #f0f0f0;
}

.leaderboard-form .points-legend {
  margin-bottom: 20px;
  font-size: 14px;
  color: #666;
}

.leaderboard-form .points-legend span {
  margin-right: 15px;
}

@media (max-width: 768px) {
  .admin-panel {
    padding: 20px;
  }

  .tabs button {
    padding: 10px 15px;
    font-size: 14px;
  }

  .leaderboard-form .leaderboard-grid {
    overflow-x: auto;
  }

  .leaderboard-form .header-cell,
  .leaderboard-form .event-cell,
  .leaderboard-form .position-cell {
    min-width: 120px;
  }
}
