.header {
  background-color: rgba(6, 12, 34, 0.98);
  padding: 10px 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 24px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.logo a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.logo a:hover {
  color: #f82249;
}

.logo span {
  color: #f82249;
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.menu-toggle:hover {
  color: #f82249;
}

.nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav ul li {
  margin-left: 20px;
}

.nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 4px;
  transition: color 0.3s ease;
}

.nav ul li a:hover,
.nav ul li a.active {
  color: #f82249;
}

@media (max-width: 768px) {
  .container {
    flex-wrap: wrap;
  }

  .logo {
    flex: 1;
  }

  .menu-toggle {
    display: block;
    order: 2;
  }

  .nav {
    flex-basis: 100%;
    order: 3;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, padding 0.3s ease-out;
  }

  .nav.open {
    max-height: 300px;
    padding: 20px 0;
  }

  .nav ul {
    flex-direction: column;
  }

  .nav ul li {
    margin: 10px 0;
  }
}

