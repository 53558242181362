.welcome-title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    color: #0e1b4d;
    margin-bottom: 30px;
}

.home-content {
    width: 100%;
}

.mobile-section-header {
    display: block;
    margin-bottom: 20px;
    text-align: center;
}