.events-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.main-title {
    color: #0e1b4d;
    text-align: center;
    margin-bottom: 30px;
}

section {
    margin-bottom: 30px;
}

h2 {
    color: #f82249;
    border-bottom: 2px solid #f82249;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

h3 {
    color: #0e1b4d;
    margin-top: 20px;
    margin-bottom: 10px;
}

ul {
    list-style-type: none;
    padding-left: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
}

li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    break-inside: avoid-column;
}

li:before {
    content: "•";
    color: #f82249;
    position: absolute;
    left: 0;
}

.event-schedule,
.participation-note {
    background-color: #f6f7fd;
    padding: 15px;
    border-radius: 5px;
    margin-top: 30px;
}

@media (max-width: 600px) {
    ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }
}