.leaderboard-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.deanery-item,
.parish-item {
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
}

.deanery-header,
.parish-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  background-color: #f5f5f5;
  border: none;
  text-align: left;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.name-container {
  flex: 0 0 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
}

.deanery-header:hover,
.parish-header:hover {
  background-color: #e0e0e0;
}

.parish-header {
  background-color: #f9f9f9;
  padding: 10px 15px;
}

.deanery-details,
.parish-details {
  padding: 15px;
}

.parish-item {
  margin-bottom: 5px;
  border: none;
  border-radius: 0;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

@media (max-width: 768px) {

  .deanery-header,
  .parish-header {
    font-size: 14px;
    padding: 10px;
  }

  th,
  td {
    padding: 8px;
    font-size: 14px;
  }
}